export const rideImages: {
    [park: string]: {
        [theme: string]: {
            [ride: string]: string;
        };
    };
} = {
    cawh: {
        default: {
            Blast: '/imgs/cawh/default/blast1.jpg',
            Condor: '/imgs/cawh/default/condor1.jpg',
            'Crazy River': '/imgs/cawh/default/crazyriver1.jpg',
            'Eat My Dust': '/imgs/cawh/default/eatmydust2.jpg',
            'El Rio Grande': '/imgs/cawh/default/elriogrande1.jpg',
            Goliath: '/imgs/cawh/default/goliath1.jpg',
            'Lost Gravity': '/imgs/cawh/default/lostgravity1.jpg',
            'Space Shot': '/imgs/cawh/default/spaceshot2.jpg',
            'Speed of Sound': '/imgs/cawh/default/speedofsound2.jpg',
            UNTAMED: '/imgs/cawh/default/untamed1.jpg',
            'Xpress: Platform 13': '/imgs/cawh/default/xpress1.jpg',
            "Merlin's Magic Castle": '/imgs/cawh/default/merlin1.jpg'
        },
        bright_nights: {
            Blast: '/imgs/cawh/bright_nights/blast.jpg',
            Condor: '/imgs/cawh/bright_nights/condor.jpg',
            'Crazy River': '/imgs/cawh/bright_nights/crazyriver.jpg',
            'Eat My Dust': '/imgs/cawh/bright_nights/eatmydust.jpg',
            'El Rio Grande': '/imgs/cawh/bright_nights/elriogrande.jpg',
            Goliath: '/imgs/cawh/bright_nights/goliath.jpg',
            'Lost Gravity': '/imgs/cawh/bright_nights/lostgravity.jpg',
            'Space Shot': '/imgs/cawh/bright_nights/spaceshot.jpg',
            'Speed of Sound': '/imgs/cawh/bright_nights/speedofsound.jpg',
            Untamed: '/imgs/cawh/bright_nights/untamed.jpg',
            'Xpress: Platform 13': '/imgs/cawh/bright_nights/xpress.jpg',
            "Merlin's Magic Castle": '/imgs/cawh/bright_nights/merlin.jpg'
        }
    }
};
