import React from 'react';
import { WaitTimeScreenTable } from './WaitTimeScreenTable';

/**
 * Displaying the waiting time screen in landscape mode on top of a background image.
 * Live on various locations in the park.
 */
export const WaitTimeScreenTableLandscape = () => {
    return (
        <div
            className="w-[1920px] h-[1080px]"
            style={{
                backgroundImage: `url('/landscape.png')`,
                backgroundSize: 'cover'
            }}
        >
            <div className={`pt-[300px]`}>
                <WaitTimeScreenTable scale="scale-[115%]" />
            </div>
        </div>
    );
};
